* {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  padding: 60px;
  background-image: url('./images/wave.svg');
  background-size: auto;
  background-repeat: no-repeat;
}

.divForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

form {
  width: 70%;
}

input {
  color: gray;
  font-size: 1.2rem;
  width: 100%;
}

h1 {
  text-align: center;
}

h3 {
  text-align: center;
  border-bottom: 2px solid gray;
  box-shadow: 0 2px 0 rgba(255, 0, 0, 0.37);
  font-variant-caps: all-petite-caps;
}

button {
  width: 15%;  
  border-radius: 5px;
  border: 1px solid #fff;
  box-shadow: 1px 1px 0 blue;
}

.btnDelete {
  background-color: rgb(221, 62, 62);
}

.btnSearch {
  background-color: rgb(103, 53, 240);
}

.fa-search-plus,
.fa-trash-alt {
  color: white;
}

.divCard {
  align-content: center;
  border: 1px solid gray;
  box-shadow: 2px 2px 0 rgba(0, 140, 255, 0.363);  
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-right: 10px;
}

.divCard p {
  padding: 5px;
  text-align: center;
  text-transform: capitalize;
  font-size: small;
}

.divCard img{
  max-height: 150px;  
  display: block;
  margin: auto;
}

.divCard-Grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

footer {
  text-align: center;
  font-variant-caps: small-caps;
}

footer a {
  text-decoration: none;
}